import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Day from "./pages/Day";
import Evening from "./pages/Evening";
import NoMatch from "./pages/NoMatch";

function App() {
  return (
    <>
      <Router>
        {/* <Nav /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/day" element={<Day />} />
          <Route path="/evening" element={<Evening />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
      {/* <Footer /> */}
    </>
  );
}

export default App;
