import React from "react";
import pumpkins from "../../assets/imgs/Pumpkinfamily.png";

const Footer = () => (
  <div className="footer">
    <div className="footer-overlay">
      <p>Copyright &#169; 2024 hutchinsonfarrierwedding.co.uk</p>
      <div className="pumpkin-family">
        <img src={pumpkins} alt="" />
      </div>
    </div>
  </div>
);

export default Footer;
