import StepTemplate from "../StepTemplate";
import { useFieldArray } from "react-hook-form";
import Radio from "../../NewForm/Radio";
import Input from "../../NewForm/Input";

import styles from "./styles.module.css";

import { TiDelete } from "react-icons/ti";
import { IoPersonAdd } from "react-icons/io5";

import { motion } from "framer-motion";

const Step1 = ({
  control,
  errors,
  currentStep,
  onSubmit,
  handlePrevStep,
  register,
}) => {
  // const { numOfGuests, setNumOfGuests } = useContext(GuestContext);

  // const addGuest = (e) => {
  //   e.preventDefault();
  //   setNumOfGuests((currGuests) => [...currGuests, currGuests.length + 1]);
  // };

  const { fields, append, remove } = useFieldArray({
    name: "guests",
    control,
  });

  // const [count, setCount] = useState(0);

  // console.log();

  // useEffect(() => {
  //   setCount(count + 1);
  // }, []);

  return (
    <StepTemplate
      title="Guest Details"
      currentStep={currentStep}
      onSubmit={onSubmit}
      handlePrevStep={handlePrevStep}
    >
      {fields.map((field, index) => (
        <fieldset key={field.id} className={styles.guestWrapper}>
          <legend>
            <h3 className={styles.guest}>Guest #{index + 1}</h3>
          </legend>
          <section style={{ marginRight: "1rem" }} key={field.id}>
            {/* REMOVE USER */}
            {fields.length > 1 && index > 0 && (
              <motion.button
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                whileTap={{ scale: 0.9 }}
                className={styles.removeGuest}
                onClick={() => remove(index)}
              >
                <TiDelete />
              </motion.button>
            )}
            {/* USER DETAILS */}
            <h2 className={styles.dietHeader}>Guest Details</h2>
            <div className={styles.userDetailsWrapper}>
              <Input
                placeholder="First Name"
                register={{
                  ...register(`guests.${index}.firstName`, {
                    required: "This is required.",
                  }),
                }}
                error={errors?.guests?.[index]?.firstName?.message}
              />
              <Input
                placeholder="Surname"
                register={{
                  ...register(`guests.${index}.surName`, {
                    required: "This is required.",
                  }),
                }}
                error={errors?.guests?.[index]?.surName?.message}
              />
            </div>
            {/* ATTENDENCE */}
            <div className={styles.attendenceWrapper}>
              <h2 className={styles.attendenceHeader}>Can you attend?</h2>
              <div className={styles.attendenceOpsWrapper}>
                <Radio
                  labelMain="Gratefully accept"
                  value="Gratefully accept"
                  register={{
                    ...register(`guests.${index}.attendence`, {
                      required: "This is required.",
                    }),
                  }}
                />
                <Radio
                  labelMain="Regretfully decline"
                  value="Regretfully decline"
                  register={{
                    ...register(`guests.${index}.attendence`, {
                      required: "This is required.",
                    }),
                  }}
                />
              </div>
              <p className="form-error">
                {errors?.guests?.[index]?.attendence?.message}
              </p>
            </div>
          </section>
        </fieldset>
      ))}
      <motion.button
        whileHover={{ scale: 1.1 }}
        transition={{ stiffness: 400, damping: 10 }}
        whileTap={{ scale: 0.9 }}
        className={styles.addGuest}
        type="button"
        onClick={() =>
          append({
            firstName: "",
            surName: "",
            attendence: "",
            alcoholFree: "",
            dietReq: "",
          })
        }
      >
        <span className={styles.addGuestTxt}>Add Guest</span>
        <span className={styles.addGuestIcon}>
          <IoPersonAdd />
        </span>
      </motion.button>
    </StepTemplate>
  );
};

export default Step1;
