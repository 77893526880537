import { useState } from "react";
import { useForm } from "react-hook-form";
import Step1 from "./Step1";

import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

import styles from "../Rsvp/styles.module.css";

const GuestForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const steps = [Step1];
  const StepComponent = steps[currentStep - 1];

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      guests: [
        {
          firstName: "",
          surName: "",
          attendence: "",
        },
      ],
    },
  });

  const pushData = async (data) => {
    const { guests } = data;

    try {
      const res = await addDoc(collection(db, "Evening"), {
        guests,
      });
      if (res) setFormSubmitted(true);
    } catch (err) {
      // TODO: add popup for user to let them know submissions failed
      console.error(err);
    }
  };

  const submitGuestData = (data) => {
    pushData(data);
  };

  return (
    <div className={styles.formWrapper} id="evening">
      <p
        style={{
          alignSelf: "flex-start",
          marginTop: ".5rem",
          marginBottom: ".5rem",
          marginLeft: "11rem",
          fontWeight: "bold",
          visibility: !formSubmitted ? "visible" : "hidden",
        }}
      >
        Only invited guests, no plus ones.
      </p>
      {StepComponent && (
        <StepComponent
          register={register}
          control={control}
          errors={errors}
          currentStep={currentStep}
          onSubmit={handleSubmit(submitGuestData)}
          handlePrevStep={false}
          formData={formData}
          setValue={setValue}
          getValues={getValues}
          formSubmitted={formSubmitted}
        />
      )}
    </div>
  );
};

export default GuestForm;
