import React from "react";
import { styled } from "styled-components";

const InfoBox = ({ title, text, link, right }) => (
  <Box right={right}>
    <h3>{title}</h3>
    <p>
      {text}{" "}
      {link ? (
        <a
          href="https://platform.hostology.co.uk/?_gl=1*1opvlsv*_ga*MTgwNzEwNTgzOS4xNzE3Njk4NTcx*_ga_7RXZ9KGW1B*MTcxNzY5ODU3MS4xLjEuMTcxNzY5ODU5OC4wLjAuMA..*_ga_LPNBQZ1ZPV*MTcxNzY5ODU3MS4xLjEuMTcxNzY5ODU5OC4zMy4wLjA.&_ga=2.135622962.2018936337.1717698573-1807105839.1717698571"
          target="_blank"
          rel="noreferrer"
        >
          Hostology.co.uk
        </a>
      ) : (
        ""
      )}
    </p>
  </Box>
);

const Box = styled.div`
  position: relative;
  text-align: ${(props) => (props.right ? "end" : "start")};
  z-index: 7;
  h3 {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
    letter-spacing: 1px;
    line-height: 1.3rem;
    font-family: "Dancing Script", cursive;
    font-style: normal;
    color: #b8331c;
    z-index: 7;
  }

  p {
    font-size: 1.2rem;
    z-index: 7;
  }

  a {
    text-decoration: none;
    color: #ff6100;
    z-index: 7;
  }

  @media only screen and (min-width: 1025px) {
    // height: 200px;
    width: 400px;
    text-align: start !important;

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1.5rem;
    }
  }
`;

export default InfoBox;
