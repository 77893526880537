import React from "react";
import Venue from "../../components/Map/Venue";
import Map from "../../components/Map/Map";
import Timeline from "../../components/Timeline/TimeLineCont";
import Info from "../../components/Info";
import GuestForm from "../../components/GuestForm";
import Footer from "../../components/Footer";
import kite from "../../assets/imgs/hexakite.png";
import branch from "../../assets/imgs/branches.png";
import pumpkin from "../../assets/imgs/pumpkins2.png";
import bridge from "../../assets/imgs/bridge.jpeg";

const Evening = () => {
  return (
    <>
      <div className="details">
        <div className="details-text">
          <h2 className="details-head hidden">
            <span className="fancy-text">We're getting married!</span>
            <br />
            OCT 31ST 2024
          </h2>
          <p className="hidden">Come celebrate with us!</p>
          <div className="kiteBox">
            <img src={kite} alt="" />
          </div>
        </div>
        <div className="details-box-container">
          <div className="branchBox">
            <img src={branch} alt="" />
          </div>
          <div className="pumpkinBox">
            <img src={pumpkin} alt="" />
          </div>
          <Venue />
          <Map />
        </div>
      </div>
      <Timeline evening />
      <Info img={bridge} />
      <GuestForm />
      <Footer />
    </>
  );
};

export default Evening;
