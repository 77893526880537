import { useState } from "react";
import Timeline from "./Timeline";
import Button from "../Button";
import options from "../../data/MarkerOptions.json";
import styles from "./styles.module.css";

const TimeLineCont = ({ all }) => {
  const [markers, setMarkers] = useState(all ? options.thur : options.evening);

  return (
    <div className={`${styles.mainContainer}`}>
      <h2 className={`${styles.timelineHead} hidden`}>Order of the Day</h2>
      <div className={styles.btnWrapper}>
        <Button
          text="Thur"
          cn={`${styles.timeLineBtn} ${
            markers.length > 4 ? styles.timeLineBtnActive : ""
          } ${!all ? styles.timeLineBtnActive : ""}`}
          onClick={() => setMarkers(all ? options.thur : options.evening)}
        />
        {all && (
          <Button
            text="Fri"
            cn={`${styles.timeLineBtn} ${
              markers.length < 4 ? styles.timeLineBtnActive : ""
            }`}
            onClick={() => setMarkers(options.fri)}
          />
        )}
      </div>
      <div className={styles.overlay}></div>
      <Timeline markers={markers} all={all} />
    </div>
  );
};

export default TimeLineCont;
