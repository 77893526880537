import styles from "./styles.module.css";
import { useFieldArray } from "react-hook-form";
import { IoPersonAdd } from "react-icons/io5";
import { MdCheck } from "react-icons/md";
import { motion } from "framer-motion";

const StepTemplate = ({
  title,
  control,
  onSubmit,
  children,
  formSubmitted,
}) => {
  // const { append } = useFieldArray({
  //   name: "guests",
  //   control,
  // });

  console.log(formSubmitted);

  return (
    <form
      autoComplete="off"
      data-lpignore="true"
      className={styles.masterForm}
      onSubmit={onSubmit}
    >
      {title && <h2 className={styles.title}>{title}</h2>}

      <div className={styles.formContainer}>{children}</div>

      <div className={styles.btnWrapper}>
        {/* <motion.button
          whileHover={{ scale: 1.1 }}
          transition={{ stiffness: 400, damping: 10 }}
          whileTap={{ scale: 0.9 }}
          className={styles.addGuest}
          type="button"
          onClick={() =>
            append({
              firstName: "",
              surName: "",
              attendence: "",
            })
          }
        > */}
        {/* <span className={styles.addGuestTxt}>Add Guest</span>
          <span className={styles.addGuestIcon}>
            <IoPersonAdd />
          </span>
        </motion.button> */}
        <motion.div
          whileHover={{ scale: formSubmitted ? 1 : 1.1 }}
          transition={{
            stiffness: 400,
            damping: 10,
          }}
          whileTap={{ scale: formSubmitted ? 1 : 0.9 }}
        >
          <button className={styles.btn} disabled={formSubmitted} type="submit">
            <MdCheck />
          </button>
        </motion.div>
      </div>
    </form>
  );
};

export default StepTemplate;
