const Venue = () => {
  return (
    <div className="venue">
      <img src={require("../../assets/imgs/SE3.jpg")} alt="Site" />
      <p>
        Ceremony and reception set to unfold at <br />
        <span className="name">Shortflatt Tower</span> <br />
        Belsay, Northumberland, NE20 0HD
      </p>
    </div>
  );
};

export default Venue;
