import styles from "./styles.module.css";
import { motion } from "framer-motion";
import { WiCloudUp } from "react-icons/wi";

const Button = ({
  type = "button",
  disabled = false,
  text,
  onClick,
  cn,
  icon,
}) => (
  <motion.div
    whileHover={{ scale: disabled ? 1 : 1.1 }}
    transition={{
      // type: disabled ? "" : "spring",
      stiffness: 400,
      damping: 10,
    }}
    whileTap={{ scale: disabled ? 1 : 0.9 }}
  >
    <button
      className={`${styles.btn} ${cn}`}
      type={type}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {text}
      {icon && <WiCloudUp />}
    </button>
  </motion.div>
);

export default Button;
