import React from "react";

const Map = () => {
  return (
    <div className="map">
      <div className="google-map" style={{ height: "400px" }}>
        <iframe
          title="test"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2281.4142023719655!2d-1.8800997225688139!3d55.12352747285783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487de760073fce31%3A0x377c08ee98cc9e54!2sShortflatt%20Tower%20%7C%20Luxury%20Manor%20House%20Wedding%20%26%20Events%20Venue%20with%20Accommodation!5e0!3m2!1sen!2suk!4v1717692663224!5m2!1sen!2suk"
          height="400"
          width="100%"
          style={{ border: "none" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <p>
          Please use Google maps to find your way as other providers are not as
          accurate.
        </p>
      </div>
    </div>
  );
};

export default Map;
