import { useState } from "react";
import { useForm } from "react-hook-form";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Result from "./Result";

import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";

import styles from "./styles.module.css";

const RsvpForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  const steps = [Step1, Step2, Result];
  const StepComponent = steps[currentStep - 1];

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    register,
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      guests: [
        {
          firstName: "",
          surName: "",
          attendence: "",
          dietReq: "",
          alcoholFree: "",
        },
      ],
      menu: [{ starter: "", main: "" }],
    },
  });

  const guests = watch("guests");
  const menu = watch("menu");

  /*
    TODO:
    using the SETVALUE function inside the menu section to set the menu to kids once the
  */

  const addMenus = () => {
    let updatedMenuArray = [];
    let currentMenuArray = menu;

    if (guests.length < menu.length) {
      const diff = menu.length - guests.length;
      currentMenuArray.splice(-diff);
      setValue("menu", currentMenuArray);
    } else if (guests.length > menu.length) {
      for (let i = 0; i < guests.length; i++) {
        updatedMenuArray.push({
          starter: "",
          main: "",
        });
      }
      setValue("menu", updatedMenuArray);
    } else if (guests.length === menu.length) {
      return;
    }
  };

  const pushData = async (data) => {
    const { guests, menu } = data;

    guests.map((guest, index) => {
      guest.menu = menu[index];
    });
    try {
      const res = await addDoc(collection(db, "Guests"), {
        RSVPInfo: {
          guests: guests,
        },
      });
      if (res) setFormSubmitted(true);
    } catch (err) {
      // TODO: add popup for user to let them know submissions failed
      console.error(err);
    }
  };

  const handleNextStep = (data) => {
    setFormData(data);

    if (currentStep === 3) {
      pushData(data);
      return;
    } else if (currentStep === 1) {
      addMenus();
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  // create function to handle progress bar clicking
  const handleSelectedStep = async (selectedStep) => {
    // grab values from form data
    const keys = Object.values(formData);
    // console.log(await trigger([keys[selectedStep]]));
    // trigger validator from useForm on formData value
    const res = await trigger(keys[selectedStep]);
    // trigger returns true/false
    // set current step to selected step + 1 if res
    if (res) {
      if (currentStep === 1) {
        addMenus();
      }
      setCurrentStep(selectedStep + 1);
    }
  };

  return (
    <div className={styles.formWrapper} id="rsvp">
      <p
        style={{
          alignSelf: "flex-start",
          marginTop: ".5rem",
          marginBottom: ".5rem",
          marginLeft: "11rem",
          fontWeight: "bold",
          visibility: currentStep < 3 ? "visible" : "hidden",
        }}
      >
        Only invited guests, no plus ones.
      </p>
      {StepComponent && (
        <StepComponent
          register={register}
          control={control}
          errors={errors}
          currentStep={currentStep}
          onSubmit={handleSubmit(handleNextStep)}
          handlePrevStep={handlePrevStep}
          formData={formData}
          setValue={setValue}
          getValues={getValues}
          formSubmitted={formSubmitted}
        />
      )}
    </div>
  );
};

export default RsvpForm;
