import StepTemplate from "../StepTemplate";
import { useFieldArray } from "react-hook-form";
import Radio from "../../NewForm/Radio";
import Input from "../../NewForm/Input";

import styles from "./styles.module.css";

import { TiDelete } from "react-icons/ti";

import { motion } from "framer-motion";

const Step1 = ({
  control,
  errors,
  currentStep,
  onSubmit,
  handlePrevStep,
  register,
  formSubmitted = false,
}) => {
  const { fields, append, remove } = useFieldArray({
    name: "guests",
    control,
  });

  return (
    <StepTemplate
      title={formSubmitted ? "Thanks for Submitting" : "Guest Details"}
      currentStep={currentStep}
      onSubmit={onSubmit}
      handlePrevStep={handlePrevStep}
      formSubmitted={formSubmitted}
    >
      {!formSubmitted &&
        fields.map((field, index) => (
          <fieldset key={field.id} className={styles.guestWrapper}>
            <legend>
              <h3 className={styles.guest}>Guest #{index + 1}</h3>
            </legend>
            <section style={{ marginRight: "1rem" }} key={field.id}>
              {/* REMOVE USER */}
              {fields.length > 1 && index > 0 && (
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}
                  whileTap={{ scale: 0.9 }}
                  className={styles.removeGuest}
                  onClick={() => remove(index)}
                >
                  <TiDelete />
                </motion.button>
              )}
              {/* USER DETAILS */}
              <h2 className={styles.dietHeader}>Guest Details</h2>
              <div className={styles.userDetailsWrapper}>
                <Input
                  placeholder="First Name"
                  register={{
                    ...register(`guests.${index}.firstName`, {
                      required: "This is required.",
                    }),
                  }}
                  error={errors?.evening?.[index]?.firstName?.message}
                />
                <Input
                  placeholder="Surname"
                  register={{
                    ...register(`guests.${index}.surName`, {
                      required: "This is required.",
                    }),
                  }}
                  error={errors?.evening?.[index]?.surName?.message}
                />
              </div>
              {/* ATTENDENCE */}
              <div className={styles.attendenceWrapper}>
                <h2 className={styles.attendenceHeader}>Can you attend?</h2>
                <div className={styles.attendenceOpsWrapper}>
                  <Radio
                    labelMain="Gratefully accept"
                    value="Gratefully accept"
                    register={{
                      ...register(`guests.${index}.attendence`, {
                        required: "This is required.",
                      }),
                    }}
                  />
                  <Radio
                    labelMain="Regretfully decline"
                    value="Regretfully decline"
                    register={{
                      ...register(`guests.${index}.attendence`, {
                        required: "This is required.",
                      }),
                    }}
                  />
                </div>
                <p className="form-error">
                  {errors?.guests?.[index]?.attendence?.message}
                </p>
              </div>
            </section>
          </fieldset>
        ))}
      {/* <div className={styles.btnWrapper}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          transition={{ stiffness: 400, damping: 10 }}
          whileTap={{ scale: 0.9 }}
          className={styles.addGuest}
          type="button"
          onClick={() =>
            append({
              firstName: "",
              surName: "",
              attendence: "",
            })
          }
        >
          <span className={styles.addGuestTxt}>Add Guest</span>
          <span className={styles.addGuestIcon}>
            <IoPersonAdd />
          </span>
        </motion.button>
        <motion.div
          whileHover={{ scale: formSubmitted ? 1 : 1.1 }}
          transition={{
            stiffness: 400,
            damping: 10,
          }}
          whileTap={{ scale: formSubmitted ? 1 : 0.9 }}
        >
          <button className={styles.btn} disabled={formSubmitted} type="submit">
            <MdCheck />
          </button>
        </motion.div>
      </div> */}
      {formSubmitted && (
        <div className={styles.wrapper}>
          <p>
            If you need to amend any of these details,
            <br /> let one of us know.
            <br />
            We cant wait to see you there.
          </p>
        </div>
      )}
    </StepTemplate>
  );
};

export default Step1;
