import { useState } from "react";
import styles from "./styles.module.css";

const Countdown = () => {
  const [days, setDays] = useState(0);
  const [complete, setComplete] = useState(false);

  let targetDate = new Date("October 31, 2024 12:00:00 GMT").getTime();

  const countDown = () => {
    let now = new Date().getTime();
    let distance = targetDate - now;

    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));

    if (!distance) {
      setDays("00");
      setComplete(true);
    }
  };

  setInterval(countDown, 1000);

  return (
    <div className={`${styles.container} ${days ? styles.show : ""}`}>
      <h2 className={styles.timer}>
        <div className={styles.item}>
          {days} <span> Days</span>
        </div>
      </h2>
    </div>
  );
};

export default Countdown;
