import styles from "./styles.module.css";
import { MdArrowBackIosNew, MdArrowForwardIos, MdCheck } from "react-icons/md";
import { motion } from "framer-motion";
import Hex from "../../Svg/Hex";

const StepTemplate = ({
  title,
  currentStep,
  onSubmit,
  handlePrevStep,
  children,
  formSubmitted = false,
  evening,
}) => (
  <form
    autoComplete="off"
    data-lpignore="true"
    className={styles.masterForm}
    onSubmit={onSubmit}
  >
    {title && <h2 className={styles.title}>{title}</h2>}

    <div className={styles.formContainer}>{children}</div>

    <div className={styles.btnWrapper}>
      {handlePrevStep && (
        <motion.div
          style={{ position: "relative" }}
          whileHover={{ scale: currentStep !== 1 && 1.1 }}
          transition={{
            // type: disabled ? "" : "spring",
            stiffness: 400,
            damping: 10,
          }}
          whileTap={{ scale: 0.9 }}
        >
          {/* <Hex cn={styles.hexBtn} /> */}
          {/* <Hex cn={styles.hexBtn2} /> */}
          <button
            className={styles.btn}
            type="button"
            onClick={() => handlePrevStep()}
          >
            <MdArrowBackIosNew />
          </button>
        </motion.div>
      )}
      <motion.div
        whileHover={{ scale: formSubmitted ? 1 : 1.1 }}
        transition={{
          // type: disabled ? "" : "spring",
          stiffness: 400,
          damping: 10,
        }}
        whileTap={{ scale: formSubmitted ? 1 : 0.9 }}
      >
        <button className={styles.btn} disabled={formSubmitted} type="submit">
          {currentStep === 3 || evening ? <MdCheck /> : <MdArrowForwardIos />}
        </button>
      </motion.div>
    </div>
  </form>
);

export default StepTemplate;
