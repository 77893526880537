import React, { useState } from "react";
import styles from "../../Step1/styles.module.css";
import { useFieldArray } from "react-hook-form";
import Choice from "../Choice";
import Input from "../../../NewForm/Input";
import MenuOptions from "../../../../data/MenuOptions.json";
import { styled } from "styled-components";
import Toggle from "../../../Toggle";
import Checkbox from "../../../NewForm/Checkbox";

const MenuForm = ({
  field,
  index,
  errors,
  register,
  control,
  setValue,
  getValues,
}) => {
  const { fields: guestFields } = useFieldArray({
    control,
    name: "guests",
  });

  return (
    <Wrapper key={field.id}>
      <legend>
        <Guest>{guestFields[index].firstName}</Guest>
      </legend>

      <>
        {/* STARTER */}
        <Choice
          course="Starter"
          options={MenuOptions.starters}
          register={{
            ...register(`menu.${index}.starter`, {
              required: "This is required.",
            }),
          }}
          error={errors?.menu?.[index]?.starter?.message}
        />
        {/* MAIN */}
        <Choice
          course="Main"
          options={MenuOptions.mains}
          register={{
            ...register(`menu.${index}.main`, {
              required: "This is required.",
            }),
          }}
          error={errors?.menu?.[index]?.main?.message}
        />
        {/* DRINKS */}
        <Choice
          course="Alcohol Free"
          options={MenuOptions.drinks}
          register={{
            ...register(`guests.${index}.alcoholFree`, {
              required: "This is required.",
            }),
          }}
          error={errors?.menu?.[index]?.main?.message}
        />
        {/* DIET OPTIONS */}
        <div style={{ marginLeft: "1rem" }} className={styles.dietWrapper}>
          <h2 className={styles.dietHeader}>Dietry requirements?</h2>
          <div className={styles.dietOpsWrapper}>
            <Input
              placeholder="Gluten Free, Vegan, None..."
              register={{
                ...register(`guests.${index}.dietReq`, {
                  required: "This is required.",
                }),
              }}
              cn="dietInput"
            />
          </div>
          <p className="form-error">
            {errors?.guests?.[index]?.dietReq?.message}
          </p>
        </div>
      </>
    </Wrapper>
  );
};

const Wrapper = styled.fieldset`
  margin: 0 1rem 1rem 1rem;
  border: solid #ff6100;
  border-radius: 5px;
`;

const Guest = styled.h3`
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 800;
  margin-left: 0.4rem;
  margin-right: 0.6rem;
  color: #ff6100;
`;

export default MenuForm;
