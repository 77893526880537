import { Link } from "react-router-dom";
import CountDown from "../../components/Countdown";
import img from "../../assets/imgs/bridge.jpeg";
import branch from "../../assets/imgs/branches.png";
import pumpkins from "../../assets/imgs/pumpkin1.png";

const Home = () => {
  return (
    <div className="HomeContainer">
      <div className="branchImg">
        <img src={branch} alt="" />
      </div>
      <div className="pumpkinImg">
        <img src={pumpkins} alt="" />
      </div>
      <div className="banner">
        <h1 className="couple">
          Sophie <span className="amp">&amp;</span> Phil
        </h1>
        <h2 className="msg">are getting married in</h2>
        <CountDown />
      </div>
      <h2 className="date">31.10.24</h2>
      <div className="navLinksCont">
        <Link className="homeLink" to="day">
          Day guest
        </Link>
        <Link className="homeLink" to="evening">
          Evening guest
        </Link>
      </div>
    </div>
  );
};

export default Home;
