import React from "react";
import InfoBox from "../InfoBox";
import { styled } from "styled-components";
import bg from "../../assets/imgs/hands.jpeg";
import branch from "../../assets/imgs/branches.png";

const Info = ({ img }) => (
  <Cont>
    <Head className="hidden">Important information</Head>
    <InfoWrapper>
      {!img && (
        <InfoBox
          title="Ceremony"
          text="The ceremony will be unplugged so we're kindly requesting everyone keep there phones on silent in in pockets or purses."
        />
      )}
      {img ? <div className="info-img" /> : <Img />}
      <div
        style={{
          height: "350px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          zIndex: 3,
        }}
      >
        <InfoBox title="Dress code" text="Cocktail attire, no white." right />
        <br />
        <InfoBox
          title="Venue"
          text="There is plenty of free onsite parking available to all guests and the venue accepts both CASH and CARD at the bar."
        />
        <br />
        <InfoBox
          title="Rooms"
          text={`Rooms have been allocated through Hostology, if you have any issues please Login to your Hostology account at`}
          link
          right
        />
      </div>
      <Branch>
        <img src={branch} alt="" />
      </Branch>
      <Branch2>
        <img src={branch} alt="" />
      </Branch2>
    </InfoWrapper>
  </Cont>
);

const Branch2 = styled.div`
  position: absolute;
  bottom: 4rem;
  right: 2rem;
  width: 35rem;
  transform: rotate(290deg);
  z-index: 2;

  @media only screen and (min-width: 1024px) {
    bottom: -2.7rem;
    right: -17rem;
    width: 55rem;
    transform: scaleX(-1) rotate(311deg);
  }

  img {
    width: 100%;
  }
`;

const Branch = styled.div`
  position: absolute;
  top: -9.3rem;
  left: 4rem;
  width: 35rem;
  transform: rotate(50deg);
  z-index: 3;

  @media only screen and (min-width: 1024px) {
    top: -16rem;
    left: -1rem;
    width: 50rem;
    transform: rotate(0);
  }

  img {
    width: 100%;
  }
`;

const Cont = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  overflow: hidden;
  z-index: 6;
`;

const Head = styled.h2`
  font-size: 1.9rem;
  margin-bottom: 0.8rem;
  text-align: center;
  z-index: 5;

  @media only screen and (min-width: 1024px) {
    font-size: 2.6rem;
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const Img = styled.div`
  height: 300px;
  // width: 325px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${bg});
  z-index: 2;

  @media only screen and (min-width: 1025px) {
    height: 400px;
    width: 600px;
    display: flex;
    align-self: center;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export default Info;
