import React from "react";
import pumpkins from "../../assets/imgs/Pumpkinfamily.png";

const NoMatch = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "50vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ fontSize: "8rem" }}>404</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "20rem" }}>
            <img style={{ width: "100%" }} src={pumpkins} alt="" />
          </div>
          <p style={{ marginTop: "-2.5rem" }}>
            How did you get here? This isnt a page.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
