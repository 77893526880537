// dietry requirements and drinks
import { useState } from "react";
import { useFieldArray } from "react-hook-form";
import StepTemplate from "../StepTemplate";
import Choice from "./Choice";
import MenuOptions from "../../../data/MenuOptions.json";
import { styled } from "styled-components";
import Toggle from "../../Toggle";
import MenuForm from "./MenuForm";

const Step2 = ({
  control,
  errors,
  currentStep,
  onSubmit,
  handlePrevStep,
  register,
  setValue,
  getValues,
}) => {
  const { fields } = useFieldArray({
    name: "menu",
    control,
  });

  const { fields: guestFields } = useFieldArray({
    control,
    name: "guests",
  });

  const [kidsMenu, setKidsMenu] = useState(true);

  // TODO:
  // this works but since additional menu items are added it crashes currently
  // console.log("guests", guestFields[0].firstName);

  return (
    <StepTemplate
      title="Wedding Menu"
      currentStep={currentStep}
      onSubmit={onSubmit}
      handlePrevStep={handlePrevStep}
    >
      {fields.map((field, index) => (
        <>
          <MenuForm
            key={index}
            field={field}
            index={index}
            errors={errors}
            register={register}
            control={control}
            setValue={setValue}
            getValues={getValues}
          />
        </>
      ))}
    </StepTemplate>
  );
};

const Wrapper = styled.fieldset`
  margin: 0 1rem 1rem 1rem;
  border: solid red;
  border-radius: 5px;
`;

const Guest = styled.h3`
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 800;
  margin-left: 0.4rem;
  margin-right: 0.6rem;
`;

export default Step2;
